export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export function formatDateStringWithZeros(dateString) {
    const parts = dateString.split('-'); // Split the date into parts
    if (parts.length === 3) {
      const year = parts[0];
      const month = parts[1].padStart(2, '0'); // Add leading zero to month
      const day = parts[2].padStart(2, '0'); // Add leading zero to day
  
      return `${year}-${month}-${day}`;
    }
  
    return dateString; // Return the original string if it's not in the expected format
  }


  export function getEarliestDateString(dateStrings) {
    if (dateStrings.length === 0) {
      return null; // Return null if the array is empty
    }
    let earliestDateString = dateStrings[0]; // Initialize with the first date string
    for (let i = 1; i < dateStrings.length; i++) {
      if (dateStrings[i] < earliestDateString) {
        earliestDateString = dateStrings[i];
      }
    }
    return earliestDateString;
}


export function getLatestDateString(dateStrings) {
  if (dateStrings.length === 0) {
    return null; // Return null if the array is empty
  }
  let latestDateString = dateStrings[0]; // Initialize with the first date string
  for (let i = 1; i < dateStrings.length; i++) {
    if (dateStrings[i] > latestDateString) {
      latestDateString = dateStrings[i];
    }
  }
  return latestDateString;
}




// Helper function to check if there are two consecutive days before the first possible date in activeDates
function checkConsecutiveDays(firstPossibleDate, activeDates) {
  return activeDates.some((date, index, array) => {
    const currentDate = new Date(date);
    const nextDate = array[index + 1] ? new Date(array[index + 1]) : null;
    return (
      nextDate &&
      currentDate.getDate() + 1 === nextDate.getDate() &&
      currentDate.getDate() + 2 === new Date(firstPossibleDate).getDate()
    );
  });
}

export function generatePossibleDates2(_latestDate, possibleDates, activeDates) {
  console.log('possibleDates', possibleDates);
  console.log('activeDates', activeDates);
  const firstPossibleDate = new Date(possibleDates[0]);
  const firstPossibleDateConv = firstPossibleDate.toISOString().split('T')[0];
  
  const hasTwoConsecutiveDays = checkConsecutiveDays(firstPossibleDateConv, activeDates);

  const latestDate = new Date(_latestDate);
  const days = [];
  if (!hasTwoConsecutiveDays) {
    const nextDay = new Date(latestDate);
    nextDay.setDate(latestDate.getDate() + 1);
    const formattedDate = nextDay.toISOString().split('T')[0];
    days.push(formattedDate);
  }
  // const lastDate = new Date(activeDates[activeDates.length - 1]);
  // lastDate.setDate(lastDate.getDate() + 7);
  // const formattedLastDate = lastDate.toISOString().split('T')[0];
  // days.push(formattedLastDate);
  return days;
}


// export function generatePossibleDates(_latestDate, activeDates) {
//   const latestDate = new Date(_latestDate);
//   const days = [];
//   //?
//   const nextDay = new Date(latestDate);
//   nextDay.setDate(latestDate.getDate() + 1);
//   const formattedDate = nextDay.toISOString().split('T')[0];
  
//   const twoDaysBefore = new Date(nextDay);
//   twoDaysBefore.setDate(nextDay.getDate() - 3);
//   const formattedDateBefore = twoDaysBefore.toISOString().split('T')[0];

//   if (!activeDates.includes(formattedDateBefore)) {
//     days.push(formattedDate);
//   }
//     //7 days
//     for (let i = 1; i <= 7; i++) { //TURN BACK TO 8
//       const lastDate = new Date(activeDates[activeDates.length - 1]);
//       lastDate.setDate(lastDate.getDate() + 8 * i);
//       const formattedLastDate = lastDate.toISOString().split('T')[0];
//       days.push(formattedLastDate);
//     }
    
//     return days;
// }

export function generatePossibleDates(_latestDate, activeDates) {
  const latestDate = new Date(_latestDate);
  const days = [];
  //?
  // DO THE 3
  const nextDay = new Date(latestDate);
  nextDay.setDate(latestDate.getDate() + 1);
  const formattedDate = nextDay.toISOString().split('T')[0];
  
  const twoDaysBefore = new Date(nextDay);
  twoDaysBefore.setDate(nextDay.getDate() - 3);
  const formattedDateBefore = twoDaysBefore.toISOString().split('T')[0];

  if (!activeDates.includes(formattedDateBefore)) {
    days.push(formattedDate);
  }
  // DO THE 3 UP

    //7 days
    for (let i = 0; i < 500; i++) { //100
      const lastDate = new Date(activeDates[activeDates.length - 1]);
      lastDate.setDate(lastDate.getDate() + 8 + i);
      const formattedLastDate = lastDate.toISOString().split('T')[0];
      days.push(formattedLastDate);
    }

    //Days in past
    for (let i = 0; i < 500; i++) { //100
      const lastDate = new Date(activeDates[activeDates.length - 1]);
      lastDate.setDate(lastDate.getDate() + 8 + i);
      const formattedLastDate = lastDate.toISOString().split('T')[0];
      days.push(formattedLastDate);
    }
    
    return days;
}
//???
  // // for (let i = 1; i <= 7; i++) { //TURN BACK TO 8
  //   const lastDate = new Date(activeDates[activeDates.length - 1]); //gets end active purple
  //   lastDate.setDate(lastDate.getDate() + 8);
  //   // lastDate.setDate(lastDate.getDate() + 8 * i);
  //   const formattedLastDate = lastDate.toISOString().split('T')[0];
  //   days.push(formattedLastDate);
  // // }


export function getNext3DaysFromDate(dateString) {
    const startDate = new Date(dateString);
    const days = [];
    for (let i = 0; i < 2; i++) {
      const nextDay = new Date(startDate);
      nextDay.setDate(startDate.getDate() + i);
      // Format the date as "YYYY-MM-DD"
      const formattedDate = nextDay.toISOString().split('T')[0];
      days.push(formattedDate);
    }

    //Add 7 days from last day
    const lastDate = new Date(days[days.length - 1]);
    lastDate.setDate(lastDate.getDate() + 7);
    const formattedLastDate = lastDate.toISOString().split('T')[0];
    days.push(formattedLastDate);
    return days;
}

export function getNext3DaysFromDateSkip(dateString) {
  const startDate = new Date(dateString);
  const days = [];
  
  for (let i = 0; days.length < 3; i++) {
    const nextDay = new Date(startDate);
    nextDay.setDate(startDate.getDate() + i);
    const formattedDate = nextDay.toISOString().split('T')[0];
    const divInvalidExists = document.querySelector(`div.fully-booked[data-date="${formattedDate}"]`);
    
    if (divInvalidExists) {
      // Skip the invalid date and continue with the next one
      continue;
    }
    days.push(formattedDate);
  }
  return days;
}

// const standardOptions = { year: 'numeric', month: 'short', day: 'numeric' };

// export function convertISODateToReadable(datePart) {
//   const date = new Date(datePart);
//   const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1));

//   // Use a standard locale for formatting
//   const locale = 'en-US';
  
//   const formattedDate = utcDate.toLocaleDateString(locale, standardOptions);
//   return formattedDate;
// }

// export function convertISODateToReadableEnglish(datePart) {
//   const date = new Date(datePart);
//   const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));

//   const locale = 'en-US';
//   const formattedDate = utcDate.toLocaleDateString(locale, standardOptions);
//   return formattedDate;
// }

// export function convertISODateToReadableFrench(datePart) {
//   const date = new Date(datePart);
//   const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));

//   const locale = 'fr-FR';
//   const formattedDate = utcDate.toLocaleDateString(locale, standardOptions);
//   return formattedDate;
// }
const monthsEnglish = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const monthsFrench = ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'];

export function convertISODateToReadable(datePart) {
  const dateArr = datePart.split('-');
  const formattedDate = `${monthsEnglish[parseInt(dateArr[1], 10) - 1]} ${parseInt(dateArr[2], 10)}, ${dateArr[0]}`;
  return formattedDate;
}

export function convertISODateToReadableEnglish(datePart) {
  if (typeof datePart === 'string') {
    const dateArr = datePart.split('-');
    const formattedDate = `${monthsEnglish[parseInt(dateArr[1], 10) - 1]} ${parseInt(dateArr[2], 10)}, ${dateArr[0]}`;
    return formattedDate;
  }
}

export function convertISODateToReadableFrench(datePart) {
  if (typeof datePart === 'string') {
    const dateArr = datePart.split('-');
    const formattedDate = `${monthsFrench[parseInt(dateArr[1], 10) - 1]} ${parseInt(dateArr[2], 10)}, ${dateArr[0]}`;
    return formattedDate;
  }
}


export function convertISOEntireToReadable(isoString) {
  // const isoString = '2023-10-31T15:20:00Z';
  const date = new Date(isoString);
  const month = date.toLocaleString('en-US', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour >= 12 ? 'pm' : 'am';
  // Add leading zeros to minutes when they are less than 10
  const formattedMinute = minute < 10 ? `0${minute}` : minute;
  // Convert to the desired format
  const formattedDate = `${month} ${day}, ${year} at ${hour % 12 || 12}:${formattedMinute}${period}`;
  return formattedDate;
}

export function extractTimeFromReadableISO(dateString) {
  // const dateString = "October 31, 2023 at 11:00pm";
  const timePart = dateString.match(/\d{1,2}:\d{2}[ap]m/);
  if (timePart) {
    const extractedTime = timePart[0];
    return extractedTime;
  } else {
    console.log("No time found in the string.");
  }
}

export function isWeekend(date) {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
}

export function joinArrayWithCommasAndAnd(arr) {
  if (arr.length === 0) {
      return '';
  } else if (arr.length === 1) {
      return arr[0];
  } else if (arr.length === 2) {
      return `${arr[0]} and ${arr[1]}`;
  } else {
      const lastItem = arr.pop();
      return `${arr.join(', ')}, and ${lastItem}`;
  }
}


export function getCurrentTime() {
  const now = new Date();
  let hours = now.getHours();
  let minutes = now.getMinutes();
  // Convert to 12-hour format
  const isPM = hours >= 12;
  hours = hours % 12;
  hours = hours ? hours : 12; // "0" should be "12"
  minutes = minutes < 10 ? '0' + minutes : minutes;
  // Add "am" or "pm"
  const suffix = isPM ? 'pm' : 'am';
  // Construct the formatted time string
  const formattedTime = `${hours}:${minutes} ${suffix}`;
  return formattedTime;
};

export function getCurrentTimePlus1Hour() {
  const now = new Date();
  let hours = now.getHours() + 1;
  let minutes = now.getMinutes();
  
  // Check if incrementing the hours rolls over to the next day
  if (hours >= 24) {
    hours -= 24;
    // Increment the date by one day
    now.setDate(now.getDate() + 1);
  }
  // Convert to 12-hour format
  const isPM = hours >= 12;
  hours = hours % 12;
  hours = hours ? hours : 12; // "0" should be "12";
  minutes = minutes < 10 ? '0' + minutes : minutes;
  // Add "am" or "pm"
  const suffix = isPM ? 'pm' : 'am';
  // Construct the formatted time string
  const formattedTime = `${hours}:${minutes} ${suffix}`;
  return formattedTime;
}



export function convertToObjectAndString(input) {
  try {
    const obj = JSON.parse(input);

    if (typeof obj === 'object' && obj !== null) {
      return obj.toISOString();
    } else {
      return input;
    }
  } catch (error) {
    return input;
  }
}

export function convertJavaScriptDateToIsoDate(originalDate) {
  // const originalDate = new Date('Tue Oct 24 2023 02:01:16 GMT-0400 (Eastern Daylight Time)');
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
  const day = originalDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function minusOneDayISO(isoStringDate) {
    // Given an ISO string date, e.g., "2023-10-28"
  // const isoStringDate = "2023-10-28";
  // Parse the ISO string to a Date object
  const dateObject = new Date(isoStringDate);
  // Subtract 1 day by adjusting the date
  dateObject.setDate(dateObject.getDate() - 1);
  // Convert the updated date back to an ISO string
  const updatedIsoStringDate = dateObject.toISOString().split("T")[0];
  return updatedIsoStringDate;
}



export function removeTAndKeepDate(isoDate) {
  try {
    const dateObj = new Date(isoDate.date);
    if (isNaN(dateObj)) {
      throw new Error('Invalid ISO date format');
    }
    return dateObj.toISOString().split('T')[0];
  } catch (error) {
    return error.message;
  }
}


export function getLatestDateDiv(divArray) {
  if (divArray.length === 0) {
    return null; // Return null if the array is empty
  }

  let latestDate = new Date(divArray[0].getAttribute('data-date')); // Initialize with the first date
  let latestDateDiv = divArray[0]; // Initialize with the first div

  for (let i = 1; i < divArray.length; i++) {
    const currentDate = new Date(divArray[i].getAttribute('data-date'));

    if (currentDate > latestDate) {
      latestDate = currentDate;
      latestDateDiv = divArray[i];
    }
  }

  return latestDateDiv;
}


export function generateRandomPromotionCode(length) {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'; // Includes numbers and uppercase letters
    let code = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }
    return code;
} 

export function generateRandomFourDigitNumber() {
  return Math.floor(Math.random() * 9000) + 1000;
}

const randomFourDigitNumber = generateRandomFourDigitNumber();



// CONVERT DATES AND FILTER IN ORDER ////////////////////////////////

export function sortDatesLatestToEarliest(dateStrings) {
  // Convert date strings to Date objects
  const dateObjects = dateStrings.map(dateString => new Date(dateString));
  // Sort the Date objects in descending order (latest to earliest)
  const sortedDateObjects = dateObjects.sort((a, b) => b - a);
  // Convert sorted Date objects back to date strings
  const sortedDateStrings = sortedDateObjects.map(dateObject => formatDateObject(dateObject));
  return sortedDateStrings;
}

function formatDateObject(dateObject) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return dateObject.toLocaleDateString(undefined, options);
}


// Get month by period
export const getMonthByPeriod = (period) => {
  let month;
  switch (period) {
      case 1:
          month = 0;
        break;
      case 2:
          month = 2;
        break;
      case 3:
          month = 4;
        break;
      case 4:
          month = 6;
        break;
      case 5:
          month = 8;
        break;
      case 6:
          month = 10;
        break;
      default:
        month = 0;
    }
    return month;
}

export const getPeriodByMonth = (month) => {
  switch (month) {
    case 0:
    case 1:
      return 1;
    case 2:
    case 3:
      return 2;
    case 4:
    case 5:
      return 3;
    case 6:
    case 7:
      return 4;
    case 8:
    case 9:
      return 5;
    case 10:
    case 11:
      return 6;
    default:
      return 320; // Default or fallback value
  }
};

// Chat gpt
export const isDateInFuture = (isoDate) => {
  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() - 4); // Adjust to UTC-4
  // console.log('currentDate', currentDate.toISOString());
  return isoDate >= currentDate.toISOString().split('T')[0];
};

export const convertISODateToSuperReadable = (isoDate, locale) => {
  const dateObject = new Date(isoDate);
  dateObject.setDate(dateObject.getDate() + 1);

  // Define options for formatting
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  };

  // Format the date according to the specified locale
  const formatter = new Intl.DateTimeFormat(locale, options);
  return formatter.format(dateObject);
};



export function getEarliestDateDiv(divArray) {
  if (divArray.length === 0) {
    return null; // Return null if the array is empty
  }

  let earliestDate = new Date(divArray[0].getAttribute('data-date')); // Initialize with the first date
  let earliestDateDiv = divArray[0]; // Initialize with the first div

  for (let i = 1; i < divArray.length; i++) {
    const currentDate = new Date(divArray[i].getAttribute('data-date'));

    if (currentDate < earliestDate) {
      earliestDate = currentDate;
      earliestDateDiv = divArray[i];
    }
  }

  return earliestDateDiv;
}

export function isNotOneDayApart(date1, date2) {
  const oneDay = 24 * 60 * 60 * 1000; // one day in milliseconds
  const difference = Math.abs(new Date(date1) - new Date(date2));
  return difference !== oneDay;
}

export function arePromotionCodesEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = arr1.slice().sort((a, b) => a.code.localeCompare(b.code));
  const sortedArr2 = arr2.slice().sort((a, b) => a.code.localeCompare(b.code));

  return sortedArr1.every((obj1, index) => {
    const obj2 = sortedArr2[index];
    return (
      obj1.code === obj2.code &&
      obj1.year === obj2.year &&
      obj1.period === obj2.period &&
      obj1.tier === obj2.tier
      // Add more properties as needed, excluding _id
    );
  });
}

export const countriesShortToFull = {
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua And Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia And Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, Democratic Republic",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: 'Cote D"Ivoire',
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island & Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic Of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle Of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "Korea",
  KP: "North Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: 'Lao People"s Democratic Republic',
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States Of",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory, Occupied",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  BL: "Saint Barthelemy",
  SH: "Saint Helena",
  KN: "Saint Kitts And Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre And Miquelon",
  VC: "Saint Vincent And Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome And Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia And Sandwich Isl.",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard And Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad And Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks And Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UM: "United States Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis And Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};