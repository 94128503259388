import jwt from 'jsonwebtoken';
const dotenv = require('dotenv'); 
dotenv.config();

const SECRET_KEY = process.env.NEXT_PUBLIC_SECRET_KEY;

export function isTokenExpired() {
    try {
        const token = localStorage.getItem('token');
        const tokenExpiration = localStorage.getItem('tokenExpiration');

        if (token && tokenExpiration) {
            const currentTimestamp = Date.now();

            if (currentTimestamp > parseInt(tokenExpiration, 10)) {
                // Token has expired, remove it from localStorage
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpiration');
                return true; // Token is expired
            } else {
                return false; // Token is not expired
            }
        } else { //keep adding on
            // Token doesn't exist or has expired, set a new token with a 1-hour expiration
            const currentTimestamp = Date.now();
            const oneHourInMilliseconds = 60 * 60 * 1000; // 1 hour in milliseconds
            const tokenExpiration = currentTimestamp + oneHourInMilliseconds;
            // localStorage.setItem('token', 'your_new_token_here');
            // localStorage.setItem('tokenExpiration', tokenExpiration.toString());
            return false; // Token is not expired (new token is set)
        }
    } catch (error) {
        console.log('Token verification error:', error);
        return true; // If there's an error while verifying the token, it's considered expired
    }
}

// export function isTokenExpired(token) {
//     try {
//         if (typeof token !== 'string') {
//             console.error('Token is NOT a string', token);
//             return true; // Token is not a string
//         }

//         const decoded = jwt.verify(token, SECRET_KEY);
//         const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds since the UNIX epoch

//         return decoded.exp < currentTimestamp; // Compare token expiration time to current time
//     } catch (error) {
//         console.log('Token verification error:', error);
//         return true; // If there's an error while verifying the token, it's considered expired
//     }
// }





